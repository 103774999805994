<template>
  <div class="page">
    <layoutContainer>
      <div class="pay">
        <div class="order" v-if="!buyFlag">
          <p>订单：{{ orderDetail.orderCode }}</p>
          <div class="project">
            <div class="detail">产品名称：{{ orderDetail.productName }}</div>
            <p class="price">¥{{ orderDetail.coursePrice }}</p>
          </div>
        </div>
        <div class="buyed-video" v-if="buyFlag">
          <p>您已经购买过课程</p>
        </div>
        <div class="pay-type" v-if="!buyFlag">
          <p class="type-text">支付方式:</p>
          <div :class="['type', 'type-alipay', choosedPay === 'payCourse' ? 'choosed-type' : '']" @click="payCourse">
            <img
              v-show="choosedPay === 'payCourse'"
              class="choosed-icon"
              src="@/assets/choosed-pay.png"
              draggable="false"
            />
          </div>
          <div :class="['type', 'type-wechat', choosedPay === 'payWechat' ? 'choosed-type' : '']" @click="payWechat">
            <img
              v-show="choosedPay === 'payWechat'"
              class="choosed-icon"
              src="@/assets/choosed-pay.png"
              draggable="false"
            />
          </div>
          <p class="left-time" v-if="leftTime > 0">
            请在<span class="time">{{ minutes }}:{{ seconds > 9 ? seconds : `0${seconds}` }}</span
            >内完成支付即可
          </p>
          <p class="left-time" v-if="leftTime === 0">
            订单已超时
          </p>
          <div class="pay-price">
            <span class="text">应付金额:</span>
            <span class="pay-number">¥{{ orderDetail.coursePrice }}</span>
            <p class="pay-btn" @click="pay()" v-if="leftTime > 0">
              立即支付
            </p>
          </div>
        </div>
      </div>
      <div class="pay-html" v-html="payHtml"></div>
    </layoutContainer>
    <el-dialog :visible.sync="dialogVisible" width="520px">
      <p style="margin: 0 auto;display: block; text-align: center;margin: 0 auto 20px;">
        请使用微信扫描二维码 以完成支付
      </p>
      <img :src="paySrc" style="width: 240px; margin: 0 auto 20px;display: block;" />
      <p class="pay-number" style="margin: 0 auto;display: block; text-align: center;margin: 0 auto 0px;">
        ¥{{ orderDetail.coursePrice }}
      </p>
    </el-dialog>
  </div>
</template>

<script>
import {orderPay, orderDetail, getCourseDetail} from '@/api/subject'

export default {
  name: 'pay',
  data() {
    return {
      choosedPay: 'payCourse',
      payHtml: '',
      leftTime: 20 * 60 * 1000,
      payTime: 20 * 60 * 1000,
      minutes: 0,
      hasGetWechatCode: false,
      now: new Date().getTime(),
      seconds: 0,
      timer: null,
      paySrc: '',
      orderDetail: {},
      dialogVisible: false,
      buyFlag: false,
      payTimer: null
    }
  },
  created() {
    const {orderId} = this.$route.query
    orderDetail(orderId).then(res => {
      this.orderDetail = res.data
      this.payTime = res.data.remainingPaymentTime
      console.log('res: ', res)
    })
  },
  mounted() {
    this.timer = setInterval(this.countDown, 1000)
  },
  beforeDestroy() {
    this.payTimer && clearInterval(this.payTimer)
    this.payTimer = null
  },
  methods: {
    payCourse() {
      this.choosedPay = 'payCourse'
    },
    payWechat() {
      this.choosedPay = 'payWechat'
      this.getPayStatus()
    },
    getPayStatus() {
      const {courseId} = this.$route.query
      const params = {id: courseId}
      if (!this.payTimer)
        this.payTimer = setInterval(() => {
          getCourseDetail(params).then(res => {
            const {code, data, msg} = res
            if (code === 200 && data) {
              if (res.data.buyFlag) {
                this.$dialog.toast('购买成功')
                this.payTimer && clearInterval(this.payTimer)
                this.payTimer = null
                this.$router.push({
                  path: '/techVideoDetail',
                  query: {id: courseId}
                })
              }
            } else {
              this.$dialog.toast(msg)
            }
          })
        }, 1000)
    },
    pay(way) {
      let payType = ''
      if (way) {
        payType = way
      } else {
        payType = this.choosedPay === 'payCourse' ? 1 : 2
      }
      const params = {
        payType: payType,
        orderCode: this.orderDetail.orderCode,
        alipayReturnUrl: window.location.href
      }
      orderPay(params).then(item => {
        if (payType === 2 || this.choosedPay === 'payWechat') {
          this.paySrc = item.data
          this.hasGetWechatCode = true
          this.dialogVisible = true
          return
        } else {
          this.payHtml = item.data
          this.$nextTick(() => {
            document.forms[0].submit()
          })
        }
      })
    },
    countDown() {
      if (this.leftTime >= 0) {
        const now = new Date().getTime()
        this.leftTime = this.now + this.payTime * 1000 - now
        this.minutes = Math.floor(this.leftTime / 1000 / 60)
        this.seconds = Math.floor((this.leftTime / 1000) % 60)
      } else {
        clearInterval(this.timer)
        const {courseId} = this.$route.query
        this.$router.push({
          path: '/techVideoDetail',
          query: {id: courseId}
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  .type-alipay,
  .type-wechat {
    background-size: 100% 100%;
  }
  .type-alipay {
    background-image: url(./../../assets/alipay.png);
  }
  .type-wechat {
    background-image: url(./../../assets/wechatPay.png);
  }
  .buyed-video {
    height: 800px;
  }
  .pay {
    width: 1200px;
    min-height: 800px;
    margin: 0 auto;
    .order {
      margin-top: 20px;
      padding: 38px 30px 30px 30px;
      width: 100%;
      height: 256px;
      background: #fff;
      .project {
        width: 100%;
        background: rgba(239, 239, 239, 0.5);
        border-radius: 8px;
        margin-top: 31px;
        padding: 38px 30px;
        display: flex;
        justify-content: space-between;
        .detail {
          font-size: 26px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 37px;
          max-width: 800px;
        }
        .price {
          height: 45px;
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 45px;
        }
      }
    }
    .pay-type {
      height: 536px;
      margin: 54px 30px 0 30px;
      .type-text {
        height: 45px;
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 45px;
        margin-bottom: 32px;
      }
      .type {
        width: 250px;
        height: 125px;
        margin-right: 60px;
        margin-bottom: 30px;
        display: inline-block;
        border: 3px solid #fff;
        background-color: #fff;
        border-radius: 12px;
        cursor: pointer;
        position: relative;
        .choosed-icon {
          width: 53px;
          height: 43px;
          position: absolute;
          bottom: 0;
          right: -1px;
        }
      }
      .choosed-type {
        border: 3px solid #ff0000;
      }
      .left-time {
        height: 37px;
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 37px;
        margin-bottom: 40px;
        .time {
          padding: 0 10px;
          color: #ff0000;
        }
      }
      .pay-price {
        float: right;
        .text {
          height: 45px;
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 45px;
        }
      }
    }
  }
  .pay-html {
    position: fixed;
    left: 100000;
  }
  .pay-btn {
    width: 200px;
    height: 60px;
    line-height: 60px;
    color: #ffffff;
    text-align: center;
    background: #ff724c;
    font-weight: 500;
    border-radius: 30px;
    font-size: 28px;
    margin-top: 20px;
    cursor: pointer;
  }
  .pay-number {
    height: 70px;
    font-size: 40px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff724c;
    line-height: 56px;
  }
  >>> .el-dialog__body {
    padding: 20px 20px;
  }
}
</style>
